<template>
  <div class="login px-16 pt-42">
    <div class="login-text">登录</div>
    <div class="login-card bda-4 mt-16 px-16 pt-32">
      <div class="login-header d-flex justify-start font-18">
        <van-image width="32" height="32" class="mr-8" :src="appLogo ? appLogo : null" :show-loading="false">
          <template v-slot:error>加载失败</template>
        </van-image>
        XX货运公司
      </div>
      <div class="login-form mt-52" v-if="step === 1">
        <div class="form-title font-14 d-flex"><Icon name="sjhm" svgClass="mr-8" />请输入手机号码</div>
        <Field v-model="tel" type="tel" placeholder="请输入手机号" />
      </div>
      <div class="login-form mt-52" v-else>
        <div class="form-title font-14">请输入验证码</div>
        <Field v-model="code" type="tel" placeholder="请输入验证码"> </Field>
        <div class="btn-group">
          <Button v-if="countdown === 0" @click="handleSendMessage" size="mini" type="info" :loading="loading"
            >重新发送</Button
          >
          <span class="text_primary" v-else>{{ countdown }}s</span>
        </div>
        <div class="text_muted mt-8">验证码已发送至：<span class="text_main" v-hideTel="tel"></span></div>
      </div>
      <div class="mt-76 d-flex justify-center">
        <Button
          v-if="step === 1"
          @click="handleSendMessage"
          size="large"
          :loading="loading"
          :disabled="!(tel && tel.length === 11)"
          type="info"
          >获取验证码</Button
        >
        <Button
          v-else
          @click="handleLogin"
          size="large"
          :loading="loading"
          :disabled="!(code && code.length === 4)"
          type="info"
          >登录</Button
        >
      </div>
    </div>
    <div class="login-footer font-12 px-24 text-center">
      未注册的手机号验证后将自动登录，并创建个人账号登录 即代表您已同意
      <router-link
        :to="{
          path: 'agreement',
          query: {
            content: 'agreement'
          }
        }"
        >《用户服务协议》</router-link
      >
      和<router-link
        :to="{
          path: 'agreement',
          query: {
            content: '123'
          }
        }"
        >《隐私政策》</router-link
      >
    </div>
    <div class="border-style"></div>
  </div>
</template>
<script>
import { Field, Button, Image as VanImage } from 'vant';
import { defineComponent, ref, onMounted, toRaw } from '@vue/composition-api';
import { getCode } from '@api/login';
import { useUserStore } from '@/store/user';

export default defineComponent({
  name: 'Login',
  components: {
    Field,
    Button,
    VanImage
  },
  setup() {
    const tel = ref(); // 电话
    const code = ref(); // 验证码
    const countdown = ref(0); // 倒计时
    const loading = ref(false); // 按钮状态
    const step = ref(1); // 当前步骤
    const appLogo = ref('');
    const userStore = useUserStore();

    async function handleSendMessage() {
      loading.value = true;
      try {
        await getCode({
          mobile: tel.value,
          appName: '货运司机端'
        });
        loading.value = false;
        step.value = 2;
        countdown.value = 60;
        handleCountDown();
      } catch (error) {
        console.log(error);
      }
    }

    async function handleLogin() {
      loading.value = true;
      const params = toRaw({ username: tel.value, password: code.value, grant_type: code.value });
      try {
        await userStore.login(params);
      } catch (error) {
        console.error(error);
      } finally {
        loading.value = false;
      }
    }

    const handleCountDown = () => {
      if (countdown.value > 0) {
        countdown.value--;
        setTimeout(() => {
          handleCountDown();
        }, 1000);
      }
    };

    onMounted(() => {
      appLogo.value = window.__Dynamic_Config__.appLogo;
    });

    return {
      step, // 控制当前步骤
      tel, // 电话
      code, // 验证码
      countdown, // 倒计时时间
      loading, // 按钮状态
      handleLogin, // 点击登录
      appLogo,
      handleSendMessage, // 点击发送验证码
      handleCountDown // 倒计时函数
    };
  }
});
</script>
<style lang="less" scoped>
.login {
  .border-style {
    border-top: solid #f1f3f5 0.5px;
    margin-top: 25px;
  }
  .login-text {
    font-size: 0.24rem;
  }

  .van-image {
    background: url('/logo-default.png');
    background-size: contain;
  }

  .login-card {
    width: 100%;
    height: 60vh;
    background-color: white;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);
    .login-header {
      line-height: 32px;
    }

    .login-form {
      height: 0.95rem;
      position: relative;
      .form-title {
        line-height: 0.22rem;
        text-align: left;
      }
      .van-field {
        border-bottom: 1px solid #ddd;
      }
      .btn-group {
        position: absolute;
        top: 0.3rem;
        right: 0;
      }
    }
  }

  .login-footer {
    position: absolute;
    bottom: 0.4rem;
    color: #999;
  }
}
</style>
